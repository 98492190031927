<template>
  <div>
    <NextMaterialSection learnMoreButtonMode="navigate" image-src="main1.jpg" />
    <AboutCompositeSection />
    <ProductFamilySection2 info-width="wide" />
    <AreaOfUseSection />
    <CanWeHelpSection />
  </div>
</template>


<script>
// @ is an alias to /src
import NextMaterialSection from "@/components/NextMaterialSection.vue";
import AboutCompositeSection from "@/components/AboutCompositeSection.vue";
import ProductFamilySection2 from "@/components/ProductFamilySection.vue";
import AreaOfUseSection from "@/components/AreaOfUseSection.vue";
import CanWeHelpSection from "@/components/CanWeHelpSection.vue";

export default {
  name: "HomeHu",
  components: {
    NextMaterialSection,
    AboutCompositeSection,
    ProductFamilySection2,
    AreaOfUseSection,
    CanWeHelpSection,
  },
  mounted(){
    this.setMetaTags({
      title: 'vg-elektrotechnik',
      desc: 'Seit vielen Jahren bieten wir Ihnen kompetenten Service rund um die Elektroinstallation. Unsere Fachleute kümmern sich schnell und zuverlässig um Planung und Durchführung von Neuinstallationen, Wartungen oder Reparaturen.',
      keywords: 'home',
      ogType: 'website',
      ogTitle: 'VG-elektrotechnik',
      ogImage: '/images/about-us-2.jpg',
      ogImageWidth: 600,
      ogImageHeight: 315,
      ogDesc:'Seit vielen Jahren bieten wir Ihnen kompetenten Service rund um die Elektroinstallation. Unsere Fachleute kümmern sich schnell und zuverlässig um Planung und Durchführung von Neuinstallationen, Wartungen oder Reparaturen.'
    })
  },
};
</script>