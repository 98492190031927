export const defaultLanguageCode = 'de';
export const languages = [
  {
    code: "de",
    targetLink: "/de"
  },
  {
    code: "en",
    targetLink: "/en"
  }
]