import * as CONFIG from '@/config/init.js';
import urlSlug from "@/utils/urlSlug";
import translations from '../../public/data/translations.json'

export default {
  data: function () {
    return {
      CONFIG: CONFIG
    }
  },
  created() {
    if (this.languageLoaded)
      this.languageLoaded()


  },
  watch: {
    '$route'(to, from) {
      if (this.getRouteLanguage(to.path) != this.getRouteLanguage(from.path)) {
        if (this.languageChange)
          this.languageChange();
      }
      if (this.routeChange) {
        this.routeChange();
      }

    }
  },
  computed: {
    language() {
      return this.$store.state.languageCode;
    }
  },
  methods: {
    tr(objectOrString) {
      const obj = typeof objectOrString === 'object' ? objectOrString : (translations[`${objectOrString}`] ?? {});
      return obj[this.$store.state.languageCode] ?? obj[this.CONFIG.defaultLanguageCode] ?? '';
    },
    l(key) {
      var lang = window.lang
      if (lang) {
        if (lang[key]) {
          if (lang[key][this.$store.state.languageCode])
            return lang[key][this.$store.state.languageCode]
          else
            return "missing language information"
        } else return "missing language key";
      } else return "missing lang"

    },

    getRouteLanguage: function (path) {

      var segment = path.split('/');
      if (segment[1] == '') {
        return this.CONFIG.defaultLanguageCode;
      } else {
        for (const language of this.CONFIG.languages) {
          if (language.code == segment[1]) {
            return language.code;
          }
        }
      }

    },
    urlSlug,
    GTtrack(category, action, label) {
      // console.log("track " , category, action, label);
      try {
        window.dataLayer.push({
          'event': 'event',
          'category': category,
          'action': action,
          'label': label
        });
      } catch (e) {
        console.log("not found dataLayer")
      }
    },
    setDataLayer(category, action, label) {
      this.GTtrack(category, action, label);
    },
    deleteMetaTags() {
      //clear the meta tags

      document.title = ''

      let metaDesc = document.querySelectorAll('meta[name="description"]')

      if (metaDesc.length > 0) {
        let elem = metaDesc[0]
        elem.parentNode.removeChild(elem)
      }

      let metaKeywords = document.querySelectorAll('meta[name="keywords"]')

      if (metaKeywords.length > 0) {
        let elem = metaKeywords[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgTitle = document.querySelectorAll('meta[property="og:title"]')

      if (metaOgTitle.length > 0) {
        let elem = metaOgTitle[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgType = document.querySelectorAll('meta[property="og:type"]')

      if (metaOgType.length > 0) {
        let elem = metaOgType[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgSiteName = document.querySelectorAll('meta[property="og:site_name"]')

      if (metaOgSiteName.length > 0) {
        let elem = metaOgSiteName[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgUrl = document.querySelectorAll('meta[property="og:url"]')

      if (metaOgUrl.length > 0) {
        let elem = metaOgUrl[0]
        elem.parentNode.removeChild(elem)
      }

      let metaogImage = document.querySelectorAll('meta[property="og:image"]')

      if (metaogImage.length > 0) {
        let elem = metaogImage[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgImageWidth = document.querySelectorAll('meta[property="og:image:width"]')

      if (metaOgImageWidth.length > 0) {
        let elem = metaOgImageWidth[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgImageHeight = document.querySelectorAll('meta[property="og:image:height"]')

      if (metaOgImageHeight.length > 0) {
        let elem = metaOgImageHeight[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgDesc = document.querySelectorAll('meta[property="og:description"]')

      if (metaOgDesc.length > 0) {
        let elem = metaOgDesc[0]
        elem.parentNode.removeChild(elem)
      }
    },
    setMetaTags(metaTags) {
      //clear the meta tags - need this because duplicate data call - example products category list page
      this.deleteMetaTags()

      // this.setMetaTags({
      //     title: title,
      //     desc: desc,
      //     keywords:keywords,
      //     ogType:ogType,
      //     ogTitle:ogTitle,
      //     ogImage:ogImage,
      //     ogImageWidth:ogImageWidth,
      //     ogImageHeight:ogImageHeight,
      //     ogDesc:ogDesc
      // })


      //add page title
      if (metaTags.title != null) {
        document.title = metaTags.title
      } else {
        document.title = 'Meshlin'
      }

      //add meta tags
      if (metaTags.desc != null) {
        let metaDesc = document.createElement('meta')
        metaDesc.name = "description"
        metaDesc.content = metaTags.desc
        document.getElementsByTagName('head')[0].appendChild(metaDesc);
      } else {
        let metaDesc = document.createElement('meta')
        metaDesc.name = "description"
        metaDesc.content = this.lang('meta_description_homepage')
        document.getElementsByTagName('head')[0].appendChild(metaDesc);
      }

      //meta keywords
      if (metaTags.keywords != null) {
        let metaKeywords = document.createElement('meta')
        metaKeywords.name = "keywords"
        metaKeywords.content = metaTags.keywords
        document.getElementsByTagName('head')[0].appendChild(metaKeywords);
      }

      //og:type
      if (metaTags.ogType != null) {
        let metaOgType = document.createElement('meta')
        metaOgType.setAttribute('property', 'og:type')
        metaOgType.content = metaTags.ogType
        document.getElementsByTagName('head')[0].appendChild(metaOgType);
      }

      //og:title
      if (metaTags.ogTitle != null) {
        let metaOgTitle = document.createElement('meta')
        metaOgTitle.setAttribute('property', 'og:title')
        metaOgTitle.content = metaTags.ogTitle
        document.getElementsByTagName('head')[0].appendChild(metaOgTitle);
      } else {
        let metaOgTitle = document.createElement('meta')
        metaOgTitle.setAttribute('property', 'og:title')
        metaOgTitle.content = 'Meshlin'
        document.getElementsByTagName('head')[0].appendChild(metaOgTitle);
      }

      //og:url - FIX
      let metaOgUrl = document.createElement('meta')
      metaOgUrl.setAttribute('property', 'og:url')
      metaOgUrl.content = process.env.VUE_APP_META_URL + window.location.pathname
      document.getElementsByTagName('head')[0].appendChild(metaOgUrl);

      //og:image

      if (metaTags.ogImage != null) {
        let metaOgImage = document.createElement('meta')
        metaOgImage.setAttribute('property', 'og:image')
        metaOgImage.content = metaTags.ogImage
        document.getElementsByTagName('head')[0].appendChild(metaOgImage);


        //og:image:width
        if (metaTags.ogImageWidth != null) {
          let metaOgImageWidth = document.createElement('meta')
          metaOgImageWidth.setAttribute('property', 'og:image:width')
          metaOgImageWidth.content = metaTags.ogImageWidth
          document.getElementsByTagName('head')[0].appendChild(metaOgImageWidth);
        }

        //og:image:height
        if (metaTags.ogImageHeight != null) {
          let metaOgImageHeight = document.createElement('meta')
          metaOgImageHeight.setAttribute('property', 'og:image:height')
          metaOgImageHeight.content = metaTags.ogImageHeight
          document.getElementsByTagName('head')[0].appendChild(metaOgImageHeight);
        }

      } else {
        let metaOgImage = document.createElement('meta')
        metaOgImage.setAttribute('property', 'og:image')
        metaOgImage.content = process.env.VUE_APP_BASE_URL + '/android-chrome-512x512.png'
        document.getElementsByTagName('head')[0].appendChild(metaOgImage);

        //og:image:width
        let metaOgImageWidth = document.createElement('meta')
        metaOgImageWidth.setAttribute('property', 'og:image:width')
        metaOgImageWidth.content = '600'
        document.getElementsByTagName('head')[0].appendChild(metaOgImageWidth);

        //og:image:height
        let metaOgImageHeight = document.createElement('meta')
        metaOgImageHeight.setAttribute('property', 'og:image:height')
        metaOgImageHeight.content = '315'
        document.getElementsByTagName('head')[0].appendChild(metaOgImageHeight);
      }

      //og:description
      if (metaTags.ogDesc != null) {
        let metaOgDesc = document.createElement('meta')
        metaOgDesc.setAttribute('property', 'og:description')
        metaOgDesc.content = metaTags.ogDesc
        document.getElementsByTagName('head')[0].appendChild(metaOgDesc);
      } else {
        let metaOgDesc = document.createElement('meta')
        metaOgDesc.setAttribute('property', 'og:description')
        metaOgDesc.content = this.lang('meta_description_homepage')
        document.getElementsByTagName('head')[0].appendChild(metaOgDesc);
      }
    },
  }
}
