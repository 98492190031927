import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './config/router.js'
import store from './config/store.js'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import globalMI from './mixins/globalMI.js'
import {extend, localize, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';
import * as rules from "vee-validate/dist/rules";
import axios from 'axios'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
//CUSTOM CSS
import '@/css/main.min.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.prototype.$axios = axios

// Install English and Arabic locales.
localize({
  en,
  de
});


extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  },
  message: "A Játékszabályzatot kötelező elfogadnia. ",
});

const VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo, {
  container: "body",
  duration: 750,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.mixin(globalMI)
Vue.config.productionTip = false

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
setInteractionMode('eager')

new Vue({
  router,
  store,
  created() {
    AOS.init({
      disable: function() {
        const maxWidth = 991;
        return window.innerWidth < maxWidth;
      }
    });
  },
  render: h => h(App)
}).$mount('#app')
