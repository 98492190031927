<template>
  <div id="navbar">
    <b-navbar toggleable="lg" type="dark" class="padding-none">
      <b-container class="z-index-100 padding-none mobile-menu-container">
        <b-navbar-brand
          @click="setDataLayer('Fejléc', 'logo', 'logo')"
          href="/"
        >
          <img src="../assets/images/logo.jpg" alt="VG elektrotechnik logo" />
        </b-navbar-brand>
        <div class="divider resp-display--none"></div>
        <b-navbar-toggle @click="submenuVisible = false" target="nav-collapse">
          <template #default="{ expanded }">
            <b-icon scale="2" shift-h="-10" v-if="expanded" icon="x"></b-icon>
            <b-icon scale="2" shift-h="-10" v-else icon="list"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse v-model="menuVisible" id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto container--full-nav">
            <!-- <b-nav-item v-for="link in links" :key="link" class="mr-3">
              <span class="">{{ link }}</span>
            </b-nav-item> -->
            <div
              class="
                row
                flex-center-space-between
                z-index
                container--full
                navbar-content
              "
            >
              <li
                @click="
                  submenuVisible = false;
                  submenuVisible2 = false;
                  menuVisible = false;
                "
                class="margin-small--navbar-item"
              >
                <router-link
                  @click.native="setDataLayer('Fejléc', 'rolunk', 'rolunk')"
                  class="text--menu nav-item"
                  :to="
                    '/' +
                    $store.state.languageCode +
                    '/' +
                    urlSlug(l('main_menu_item_3'))
                  "
                  >{{ l("main_menu_item_3") }}
                </router-link>
              </li>
              <!--              <li
                  @click="
                        submenuVisible = false;  submenuVisible2= false;
                        menuVisible = false;
                      "
                  class="margin-small&#45;&#45;navbar-item"
              >
                <router-link
                    @click.native="setDataLayer('Fejléc', 'GYIK', 'GYIK')"
                    class="text&#45;&#45;menu nav-item"
                    :to="
                    '/' +
                    $store.state.languageCode +
                    '/' +
                    urlSlug(l('main_menu_item_4'))
                  "
                >{{ l("main_menu_item_4") }}
                </router-link>
              </li>-->
              <li
                @click="
                  submenuVisible = false;
                  submenuVisible2 = false;
                  menuVisible = false;
                "
                class="margin-small--navbar-item"
              >
                <router-link
                  @click.native="
                    setDataLayer('Fejléc', 'kapcsolat', 'kapcsolat')
                  "
                  class="text--menu nav-item"
                  :to="
                    '/' +
                    $store.state.languageCode +
                    '/' +
                    urlSlug(l('main_menu_item_5'))
                  "
                  >{{ l("main_menu_item_5") }}
                </router-link>
              </li>
              <!--              <div class="flex-row language-selector resp-display">-->
              <!--                <li-->
              <!--                    class="flex-row"-->
              <!--                    v-for="(l, index) in CONFIG.languages"-->
              <!--                    :key="l.id"-->
              <!--                    :class="{ current: $store.state.languageCode === l.code }"-->
              <!--                >-->
              <!--                  <img-->
              <!--                      v-if="index > 0"-->
              <!--                      v-show="$store.state.languageCode === l.code"-->
              <!--                      class="margin-small&#45;&#45;navbar-icon"-->
              <!--                      src="../assets/images/svgs/navbar-vector.svg"-->
              <!--                  />-->
              <!--                  <img-->
              <!--                      v-if="index > 0"-->
              <!--                      v-show="$store.state.languageCode !== l.code"-->
              <!--                      class="margin-small&#45;&#45;navbar-icon"-->
              <!--                      src="../assets/images/svgs/navbar-vector2.svg"-->
              <!--                  />-->
              <!--                  <router-link-->
              <!--                      :to="l.targetLink"-->
              <!--                      class="text&#45;&#45;menu text-uppercase nav-link"-->
              <!--                  >-->
              <!--                    {{ l.code }}-->
              <!--                  </router-link>-->
              <!--                </li>-->
              <!--              </div>-->
              <div
                class="
                  divider--navbar
                  center
                  resp-display--none
                  text--menu
                  margin-lr-auto
                "
              ></div>
              <div class="flex-row navbar-arrow-animation">
                <a
                  class="flex-row text--navbar-link navbar-link-margin"
                  href="mailto:info@vg-elektrotechnik.com"
                  >{{ tr("askOffer") }}
                  <div class="icon--navbar navbar-arrow" />
                </a>
              </div>
              <!--              <div-->
              <!--                  class="flex-row language-selector resp-display&#45;&#45;none container&#45;&#45;lang navbar-link-margin&#45;&#45;large"-->
              <!--              >-->
              <!--                <li-->
              <!--                    class="flex-row"-->
              <!--                    v-for="(l, index) in CONFIG.languages"-->
              <!--                    :key="l.id"-->
              <!--                    :class="{ current: $store.state.languageCode === l.code }"-->
              <!--                >-->
              <!--                  <img-->
              <!--                      v-if="index > 0"-->
              <!--                      v-show="$store.state.languageCode === l.code"-->
              <!--                      class="margin-small&#45;&#45;navbar-icon"-->
              <!--                      src="../assets/images/svgs/navbar-vector.svg"-->
              <!--                  />-->
              <!--                  <img-->
              <!--                      v-if="index > 0"-->
              <!--                      v-show="$store.state.languageCode !== l.code"-->
              <!--                      class="margin-small&#45;&#45;navbar-icon"-->
              <!--                      src="../assets/images/svgs/navbar-vector2.svg"-->
              <!--                  />-->
              <!--                  <router-link-->
              <!--                      :to="l.targetLink"-->
              <!--                      class="text&#45;&#45;menu margin-none text-uppercase nav-link"-->
              <!--                  >-->
              <!--                    {{ l.code }}-->
              <!--                  </router-link>-->
              <!--                </li>-->
              <!--              </div>-->
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <div
      v-if="submenuVisible"
      @click="submenuVisible = false"
      class="navbar-backdrop"
    ></div>
    <div
      v-if="submenuVisible2"
      @click="submenuVisible2 = false"
      class="navbar-backdrop"
    ></div>
    <div class="flex-row flex-center-space-between container--full">
      <b-collapse
        class="position-absolute navbar-product-accordion"
        v-model="submenuVisible"
        id="accordion-2"
        accordion="my-accordion"
        role="tabpanel"
      >
        <div class="flex-row flex-center-space-between divider">
          <a
            block
            v-b-toggle.accordion-2
            class="
              text--menu
              margin-small--horizontal
              padding-navbar-collapse
              margin-small--vertical
              dflex
              resp-display-menu
            "
          >
            <img
              class="icon m-md-1"
              src="@/assets/images/svgs/chevron-left.svg"
            />
            {{ tr("navbarMenu") }}
          </a>
          <!--          <a block v-b-toggle.accordion-2>-->
          <!--            <b-navbar-toggle block v-b-toggle.accordion-2 target="nav-collapse" class>-->
          <!--              <img class="icon m-md-1" src="@/assets/images/svgs/menu-x.svg"/>-->
          <!--            </b-navbar-toggle>-->
          <!--          </a>-->
        </div>
        <b-card-body
          class="
            scroll-container
            padding-none
            background-black
            menu-card-height
          "
        >
          <div class="container background-black">
            <div
              class="
                flex-row flex-wrap
                dflex
                margin-medium--bottom
                justify-content-between
              "
            >
              <ul
                class="
                  title--footer
                  padding-none
                  margin-navbar-product
                  col-sm-6 col-md-6 col-lg6
                "
                v-for="productFamily in productFamilies"
                :key="tr(productFamily.id)"
              >
                <li class="padding-none container--480 margin-lr-auto">
                  <div class="margin-small--bottom--10">
                    <a
                      @click="
                        setDataLayer(
                          'Fejléc',
                          'termekek',
                          productFamily.name.hu
                        )
                      "
                      :href="getProductFamilyHref(tr(productFamily.id))"
                      class="title--footer--white text-capitalize"
                    >
                      {{ tr(productFamily.name) }}
                    </a>
                  </div>
                  <ul class="padding-none">
                    <li
                      @click="
                        submenuVisible = false;
                        menuVisible = false;
                      "
                      v-for="product in productFamily.products"
                      :key="tr(product.id)"
                      class="margin-small--bottom--10 padding-none"
                    >
                      <a
                        @click="
                          setDataLayer('Fejléc', 'termekek', product.name.hu)
                        "
                        class="
                          text--main-text
                          product-hover
                          text-capitalize
                          padding-none
                        "
                        :href="
                          getProductHref(
                            tr(product.productFamilyId),
                            tr(product.id)
                          )
                        "
                      >
                        <div class="row m-0">
                          <div>{{ tr(product.name) }}</div>
                          <span
                            v-if="tr(product.nameAddition)"
                            class="
                              margin-small--horizontal--icon
                              d-flex
                              align-items-center
                            "
                          >
                            +
                          </span>
                          <div>{{ tr(product.nameAddition) }}</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <b-card-text></b-card-text>
        </b-card-body>
      </b-collapse>
    </div>
    <div class="flex-row flex-center-space-between container--full">
      <b-collapse
        class="position-absolute navbar-product-accordion"
        v-model="submenuVisible2"
        id="accordion-3"
        accordion="my-accordion"
        role="tabpanel"
      >
        <div class="flex-row flex-center-space-between divider">
          <a
            block
            v-b-toggle.accordion-3
            class="
              text--menu
              margin-small--horizontal
              padding-navbar-collapse
              margin-small--vertical
              dflex
              resp-display-menu
            "
          >
            <img
              class="icon m-md-1"
              src="@/assets/images/svgs/chevron-left.svg"
            />
            {{ tr("navbarMenu") }}
          </a>
        </div>
        <b-card-body
          class="
            scroll-container
            padding-none
            background-black
            menu-card-height
          "
        >
          <div class="container background-black">
            <div
              class="
                flex-row flex-wrap
                dflex
                margin-medium--bottom
                justify-content-between
              "
            >
              <ul
                class="
                  title--footer
                  padding-none
                  justify-content-center
                  margin-navbar-product
                  col-sm-6 col-md-6 col-lg-3
                "
                v-for="areaOfUse in areasOfUse"
                :key="areaOfUse.internalId"
              >
                <li class="padding-none container--150 margin-lr-auto">
                  <a
                    :href="getAreaHref(tr(areaOfUse.id))"
                    class="
                      title--footer--white--normal title--footer--white
                      margin-small--bottom--10
                    "
                  >
                    {{ tr(areaOfUse.name) }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <b-card-text></b-card-text>
        </b-card-body>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data: () => ({
    submenuVisible: false,
    submenuVisible2: false,
    menuVisible: false,
  }),
  props: {
    products: Array,
  },
  methods: {
    languageChange: function () {
      this.$forceUpdate();
    },
    getProductHref(productFamilyId, productId) {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.product`,
        params: {
          language: this.$store.state.languageCode,
          productFamilyId,
          productId,
        },
      }).href;
    },
    getProductFamilyHref(productFamilyId, productId) {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.productFamily`,
        params: {
          language: this.$store.state.languageCode,
          productFamilyId,
          productId,
        },
      }).href;
    },
    getAreaHref(id) {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.areaOfUse`,
        params: {
          language: this.$store.state.languageCode,
          areaOfUseId: id,
        },
      }).href;
    },
  },
  computed: {
    productFamilies() {
      return this.$store.state.productFamilies.filter((pf) => !pf.hidden);
    },
    areasOfUse() {
      return this.$store.state.areasOfUse;
    },
    areaOfUseId() {
      return this.$router.currentRoute.params.areaOfUseId;
    },
    areaOfUse() {
      return this.$store.state.areasOfUse.find(
        (areaOfUse) => this.tr(areaOfUse.id) === this.areaOfUseId
      );
    },
  },
};
</script>
