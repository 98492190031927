<template>
  <div class="error-background">
    <div class="error-floating-objects center margin-lr-auto flex-column">
      <div class="error-container center"></div>
      <span class="text--main-text margin-medium--top">{{ tr('404Text') }}</span>
      <router-link
          class="btn btn--green-short margin-medium--btn margin-lr-auto"
          :to="'/#' + $store.state.languageCode"
      ><img class="icon" src="@/assets/images/svgs/arrow-left-404.svg" alt="404 arrow"/>{{ tr('404Button') }}
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: "Error404"
}
</script>

<style scoped>

</style>