F<template>
  <div class="container--full background-white">
    <div class="divider--darker background-white">
      <div class="main-section--fourth flex-column">
        <div>
          <div class="center flex-column">
            <h2 class="title--black margin-spec-top margin-spec-bottom">
              {{ tr('otherUses') }}
              <!--              Felhasználási<br class="resp-display&#45;&#45;none" />-->
              <!--              területek-->
            </h2>
            <div class="center container--area-of-use">
              <TileSmall
                  v-for="areaOfUse in availableAreasOfUse"
                  :key="tr(areaOfUse.id)"
                  :title="areaOfUse.name"
                  :image="areaOfUse.image"
                  :id="tr(areaOfUse.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TileSmall from "./TileSmall";

export default {
  components: {
    TileSmall,
  },
  props: {
    filterCurrentFamily: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedItem() {
      return this.$route.params.areaOfUseId;
    },
    availableAreasOfUse() {
      return this.$store.state.areasOfUse.filter(
          (areaOfUse) =>
              !this.filterCurrentFamily ||
              this.selectedItem !== this.tr(areaOfUse.id)
      );
    },
  },
};
</script>