<template>
  <div class="center container--full">
    <div class="main-section--third mw-1920 row">
      <h2 class="title container margin-spec-top margin-spec-bottom">
        {{tr('productFamilies')}}
      </h2>
      <div class="container--tiles flex-row">
        <Tile
            v-for="productFamily in availableProductFamilies"
            :key="tr(productFamily.id)"
            :title="tr(productFamily.name)"
            :image="productFamily.image"
            :id="tr(productFamily.id)"
            :description="tr(productFamily.description)"
        />
        <ProductFamilySectionInfo />
      </div>
    </div>
  </div>
</template>

<script>
import Tile from "./Tile";
import ProductFamilySectionInfo from "./ProductFamilySectionInfo";

export default {
  data: () => ({
    // products: [
    //   {
    //     id: "prepegek",
    //     title: "Prepegek",
    //     image: "tile1.jpg",
    //   },
    //   {
    //     title: "Design panelek",
    //     image: "tile3.jpg",
    //     id: "design-panelek",
    //   },
    //   {
    //     title: "Bútor és asztalosipari termékek",
    //     image: "tile4.jpg",
    //     id: "butor",
    //   },
    //   {
    //     id: "hajo",
    //     title: "Hajó-, lakókocsi és mobilház panelek",
    //     image: "tile5.jpg",
    //   },
    //   {
    //     title: "Akusztikai megoldások",
    //     image: "tile6.jpg",
    //     id: "akusztika",
    //   },
    //   {
    //     title: "Reklámipari dekorációs panelek",
    //     image: "tile7.jpg",
    //     id: "reklamipari",
    //   },
    // ],
  }),
  components: {
    ProductFamilySectionInfo,
    Tile,
  },
  props: {
    filterCurrentFamily: {
      type: Boolean,
      default: false,
    },  
  },
  computed: {
    selectedItem() {
      return this.$route.params.productFamilyId;
    },
    availableProductFamilies() {
      return this.$store.state.productFamilies.filter(apf => !apf.hidden);

      //     .filter(
      //     (productFamily) => !this.filterCurrentFamily || this.selectedItem !== this.tr(productFamily.id)
      // );
    },
  },
};
</script>