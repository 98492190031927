<template>
  <div class="background-white">
    <ImpressumSection />
  </div>
</template>


<script>
// @ is an alias to /src
import ImpressumSection from "@/components/ImpressumSection";

export default {
  name: "Impressum",
  components: {
    ImpressumSection,
  },
};
</script>
