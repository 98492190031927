import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutUs from '@/views/AboutUs.vue'
import Faq from '@/views/FAQ'
import Contact from '@/views/Contact'
import CookiePolicy from '@/views/CookiePolicy'
import Error404 from '@/views/Error404.vue'
import Home from '@/views/Home.vue'
import store from './store';
import { languages } from './init'
import { defaultLanguageCode } from "@/config/init";
import { localize } from "vee-validate";
import Privacy from "@/views/Privacy";
import Impressum from "@/views/Impressum";
import Meta from 'vue-meta'
Vue.use(Meta)

Vue.use(VueRouter);

const pathTranslations = {
  home: {
    en: '',
    de: '',
  },
  aboutUs: {
    en: 'about-us',
    de: 'wir-uber-uns'
  },
  faq: {
    en: 'faq',
    de: 'gyik'
  },
  contact: {
    en: 'contact',
    de: 'kontakt'
  },
  cookiePolicy: {
    en: 'cookie-policy',
    de: 'cookie-policy'
  },
  privacy: {
    en: 'privacy',
    de: 'datenschutzerklarung'
  },
  impressum: {
    en: 'impressum',
    de: 'impressum'
  },
}

const routes = [
  {
    name: 'home',
    component: Home,
    default: true
  },
  {
    name: 'aboutUs',
    component: AboutUs
  },
  {
    name: 'faq',
    component: Faq
  },
  {
    name: 'contact',
    component: Contact
  },
  {
    name: 'cookiePolicy',
    component: CookiePolicy
  },
  {
    name: 'privacy',
    component: Privacy
  },
  {
    name: 'impressum',
    component: Impressum
  },
];

const languageRoutes = languages.map(language => ({
  path: language.targetLink,
  component: {
    render: (h) => {
      console.log(language);
      document.getElementsByTagName('html')[0].setAttribute('lang', language.code.toLowerCase() === 'de' ? 'de-DE' : 'en-US');
      return h('router-view')
    }
  },
  children: routes.map(route => ({
    path: pathTranslations[route.name][language.code],
    name: `${language.code}.${route.name}`,
    component: route.component,
  }))
}));
languageRoutes.push({
  path: '',
  redirect: { name: `${defaultLanguageCode}.${routes.find(route => route.default).name}` },
  component: routes.find(route => route.default).component
});
languageRoutes.push({
  path: '*',
  component: Error404,
  name: 'error404'
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: languageRoutes
})

router.afterEach(() => {
  window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
  // console.log(to);
  const languageUrlSegment = to.path.split('/');
  // Get language from path
  languageUrlSegment.shift();
  let language = languageUrlSegment[0];
  // language fallback
  if (!language) language = store.state.languageCode;
  // Set language
  to.params.language = language;
  store.commit('language', language);
  localize(language);
  if (to.params.productFamilyId || to.params.productId) {
    const family = store.state.productFamilies.find(productFamily => productFamily.id[language] === to.params.productFamilyId);
    if (!family || (to.params.productId && !family.products.find(
      (product) => product.id[language] === to.params.productId
    )
    )
    ) {
      next({ name: 'error404' })
      return;
    }
  }
  if (to.params.areaOfUseId && !store.state.areasOfUse.find(areaOfUse => areaOfUse.id[language] === to.params.areaOfUseId)) {
    next({ name: 'error404' })
    return;
  }
  // Move on the next hook (render component view)
  next();
})

export default router
