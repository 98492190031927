<template>
  <div
    class="
      container--1110
      mw-1920
      margin-lr-auto
      flex-center-space-between flex-column
    "
  >
    <h2
      id="faq_intro"
      class="
        title--black
        margin-large--top
        margin-medium--bottom
        text-center text-left
      "
    >
      DATENSCHUTZERKLÄRUNG der VG Elektrotechnik GmbH
    </h2>
    <p class="text--black padding-none">
      <strong
        >Das Unternehmen möchte die Rechtmäßigkeit der Datenverarbeitung in
        Bezug auf die von ihm verarbeiteten personenbezogenen Daten
        sicherstellen, achtet besonders auf den Schutz personenbezogener Daten,
        die Einhaltung zwingender Bestimmungen sowie eine sichere und faire
        Datenverarbeitung.</strong
      >
    </p>
    <p class="text--black">
      <strong>Der Zweck der:</strong> die von der
      <strong
        >VG Elektrotechnik GmbH angewandten Datenschutz- und
        Verarbeitungsgrundsätze,</strong
      >
      und ihre Datenschutz- und Verarbeitungsrichtlinien, die sie als
      Datenverantwortlicher
      <strong>mit verbindlicher Kraft anerkennt, darzulegen.</strong>
    </p>
    <p class="text--black">
      Bei der Gestaltung der Bestimmungen der Landschaft berücksichtigte das
      Unternehmen insbesondere die Bestimmungen der Verordnung 2016/679 des
      Europäischen Parlaments und des Rates (DSGVO), des Gesetzes CXII von 2011
      über die informationelle Selbstbestimmung und Informationsfreiheit
      ("Datenschutzgesetz") ), den Bestimmungen des Gesetzes V von 2013 über das
      Bürgerliche Gesetzbuch ("Bürgerliches Gesetzbuch").
    </p>

    <h2 class="title--black text-left margin-medium--bottom">
      I. VERANTWORTLICHER
    </h2>
    <ul>
      <li class="text--black privacy-list-item">
        Firmenname: <strong>VG Elektrotechnik GmbH</strong>
      </li>

      <i class="text--black privacy-list-item">
        Sitz: Gewerbestraße 16, 79112 Freiburg im Breisgau
      </i>

      <li class="text--black privacy-list-item">
        Nummer der Firma: DE327982928
      </li>

      <li class="text--black privacy-list-item">Steuernummer: 24913140-2-08</li>

      <li class="text--black privacy-list-item">
        E-Mail-Adresse:<a href="mailto: info@vg-elektrotechnik.com"
          >info@vg-elektrotechnik.com</a
        >
      </li>

      <li class="text--black privacy-list-item">Tel.: +49 (0) 174 2679151</li>

      <li class="text--black privacy-list-item">
        Die Organisation beschäftigt keinen Datenschutzbeauftragten.
      </li>
    </ul>

    <h2 class="title--black text-left margin-medium--bottom">DEFINITIONEN</h2>

    <p class="text--black">
      <strong>Datenverarbeitung:</strong> unabhängig vom verwendeten Verfahren,
      jedem Vorgang oder einer Reihe von Vorgängen, die mit personenbezogenen
      Daten durchgeführt werden, insbesondere der Erhebung, Aufzeichnung,
      Organisation, Strukturierung, Speicherung, Umwandlung, Änderung,
      Verwendung, Abfrage, Verwendung, Verwendung, Offenlegung, Übermittlung,
      Verbreitung, anderweitige Bereitstellung, Offenlegung, Abgleich oder
      Kombination, Einschränkung, Löschung und Vernichtung personenbezogener
      Daten.
    </p>

    <p class="text--black w-100">
      <strong>Tätigkeit des Datenverantwortlichen:</strong> Verarbeitung
      personenbezogener Daten, die während des Kontakts mitgeteilt werden
    </p>

    <p class="text--black">
      Az <strong>Recht auf Datenverarbeitung Blatt:</strong> Einwilligung der
      betroffenen Person.
      <strong
        >Personen unter 16 Jahren können der Verarbeitung nur durch die
        Person</strong
      >
      die die elterliche Sorge für sie hat, oder mit ihrer Erlaubnis zustimmen..
    </p>

    <h2 class="title--black text-left margin-medium--bottom">
      Verarbeitung von Internetbesucherdaten der Website
    </h2>

    <h2 class="title--black text-left margin-medium--bottom">
      GRUNDSÄTZE DERDATENVERARBEITUNG
    </h2>

    <p class="text--black">
      Der Datenverantwortliche verarbeitet personenbezogene Daten in
      Übereinstimmung mit den Grundsätzen von Treu und Glauben und Fairness und
      Transparenz sowie den Bestimmungen der geltenden Rechtsvorschriften und
      dieser Richtlinie.
    </p>

    <p class="text--black w-100">
      Der Datenverantwortliche verwendet die personenbezogenen Daten auf der
      Grundlage der Zustimmung des betroffenen Nutzers und nur zu diesem Zweck.
    </p>

    <p class="text--black">
      Der Datenverantwortliche kontrolliert nicht die ihm zur Verfügung
      gestelltenpersonenbezogenen Daten. Die Person, die die personenbezogenen
      Daten zur Verfügung stellt, ist allein verantwortlich für die
      Angemessenheit der bereitgestellten personenbezogenen Daten.
    </p>

    <p class="text--black">
      Die personenbezogenen Daten einer Person unter 16 Jahren dürfen nur mit
      Zustimmung des Erwachsenen, der die elterliche Sorge für ihn ausübt,
      verarbeitet werden. DerDatenverantwortliche ist nicht in der Lage, die
      Einwilligung der einwilligenden Person oder den Inhalt ihrer Erklärung zu
      überprüfen, so dass der Nutzer oder die Person, die die elterliche
      Verantwortung über ihn ausübt, garantiert, dass die Zustimmung dem Gesetz
      entspricht. In Ermangelung einer begleitenden Erklärung erhebt der
      Datenverantwortliche keine personenbezogenen Daten einer betroffenen
      Person unter 16 Jahren, mit Ausnahme einer IP-Adresse, die aufgrund der
      Art der Internetdienste automatisch aufgezeichnet wird.
    </p>
    <p class="text--black">
      Der Datenverantwortliche übermittelt die von ihmverarbeiteten
      personenbezogenen Daten nicht an andere Dritte als die in dieser
      Mitteilung genannten Datenverarbeiter, die Mitarbeiter des Unternehmens
      und in einigen Fällen an die externen Dienstleister.
    </p>
    <p class="text--black">
      In bestimmten Fällen - Amtsgericht,polizeiliche Anfrage,
      Gerichtsverfahren, Urheberrechts-, Wagen- oder andere Verstöße oder
      begründeter Verdacht darauf - stellt der Datenverantwortliche die zur
      Verfügung stehenden personenbezogenen Daten des betroffenen Nutzers
      Dritten zur Verfügung.
    </p>
    <p class="text--black">
      Das System des Datenverantwortlichen kann Daten über die Aktivität des
      Benutzers sammeln, die nicht mit anderen Daten verknüpft werden können,
      dievon den Nutzern zum Zeitpunkt der Registrierung zur Verfügung gestellt
      werden, noch mit Daten, die bei der Nutzung anderer Websites oder Dienste
      generiert werden.
    </p>
    <p class="text--black">
      Der Datenverantwortliche gewährleistet die Sicherheit der
      personenbezogenen Daten, ergreift dietechnischen und organisatorischen
      Maßnahmen und legt die Verfahrensregeln fest, die sicherstellen, dass die
      aufgezeichneten, gespeicherten und verarbeiteten Daten geschützt sind, und
      verhindert deren zufälligen Verlust, unrechtmäßige Zerstörung, unbefugten
      Zugriff, unbefugte Nutzung und unbefugte Änderung und unbefugte
      Verbreitung.
    </p>
    <h2 class="title--black text-left margin-medium--bottom">
      INFORMATIONEN ZU DEN RECHTEN DER BETROFFENEN PERSON
    </h2>

    <p class="text--black">
      Recht auf Vorabinformation Die betroffene Person hat das Recht, vor Beginn
      der Datenverarbeitung über die mit der Datenverarbeitung zusammenhängenden
      Tatsachen und Informationen unterrichtet zu werden . (Artikel 13 bis 14
      der Verordnung)
    </p>
    <p class="text--black">
      Auskunftsrecht der betroffenen Person Die betroffene Person hat das Recht,
      von dem für die Verarbeitung Verantwortlichen eine Bestätigung darüber zu
      verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden
      oder nicht, und, falls dies der Fall ist, Zugang zu den in der Verordnung
      genannten personenbezogenen Daten und den damit zusammenhängenden
      Informationen. (Artikel 15 der Verordnung).
    </p>
    <p class="text--black">
      Recht auf Berichtigung Die betroffene Person hat das Recht, vondem
      Verantwortlichen unverzüglich die Berichtigung sie betreffender
      unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung
      der Zwecke der Verarbeitung hat die betroffene Person das Recht, die
      Vervollständigung unvollständiger personenbezogener Daten, auch mittels
      einer ergänzenden Erklärung, zu verlangen . (Artikel 16 der Verordnung).
    </p>
    <p class="text--black">
      Recht auf Löschung ("Recht auf Vergessenwerden") Die betroffene Person hat
      das Recht, von dem Verantwortlichen zu verlangen, dass die sie
      betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der
      Verantwortliche ist verpflichtet, personenbezogene Daten unverzüglich zu
      löschen, sofern einer der in der Verordnung genannten Gründe zutrifft.
      (Artikel 17 der Verordnung)
    </p>
    <p class="text--black">
      Recht auf Einschränkung der Verarbeitung Die betroffene Person hat das
      Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu
      verlangen , wenn die in der Bestellung genannten Voraussetzungen erfüllt
      sind. (Artikel 18 der Verordnung)
    </p>
    <p class="text--black">
      Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung
      personenbezogener Daten oder der Einschränkung der Verarbeitung Der
      Verantwortliche teilt jedem Empfänger, dem die personenbezogenen Daten
      offengelegt wurden, jede Berichtigung oder Löschung der personenbezogenen
      Daten oder Einschränkung der Verarbeitung mit, es sei denn, dies erweist
      sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand
      verbunden. Auf Antrag der betroffenen Person teilt der für die
      Verarbeitung Verantwortliche diese über diese Empfänger mit. (Artikel 19
      der Verordnung)
    </p>
    <p class="text--black">
      Recht auf Datenübertragbarkeit Unter den in der Verordnung festgelegten
      Bedingungen hat die betroffene Person das Recht, die sie betreffenden
      personenbezogenen Daten, die sie einem Verantwortlichen bereitgestellt
      hat, in einem strukturierten, marginalen, weit verbreiteten und
      maschinenlesbaren Format zu erhalten, und sie hat das Recht, diese Daten
      einem anderen Verantwortlichen ohne Behinderung durch den
      Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden,
      zu übermitteln. (Verordnung Artikel 20
    </p>
    <p class="text--black">
      Widerspruchsrecht Die betroffene Person hat das Recht, aus Gründen, die
      sich aus ihrer besonderen Situation ergeben, jederzeit gegen ihre
      personenbezogenen Daten gemäß Artikel 6 Absatz 1 Buchstabe e der
      Verordnung (die Verarbeitung ist für die Wahrnehmung einer Aufgabe
      erforderlich, die im öffentlichen Interesse liegt oder in Ausübung
      öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde)
      oder Buchstabe f (die Verarbeitung ist zur Wahrung der berechtigten
      Interessen des Verantwortlichen oder eines Dritten erforderlich)
      Widerspruch einzulegen. Artikel 21 der Verordnung)
    </p>
    <p class="text--black">
      Automatisierte Entscheidungsfindung im Einzelfall einschließlich Profiling
      Die betroffene Person hat das Recht, nicht einer ausschließlich auf einer
      automatisierten Verarbeitung — einschließlich Profiling — beruhenden
      Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung
      entfaltet oder sie in ähnlicherWeise erheblich beeinträchtigt. (Artikel 22
      der Verordnung)
    </p>
    <p class="text--black">
      Einschränkungen Das Unionsrecht oder das Recht der Mitgliedstaaten, dem
      der für die Verarbeitung Verantwortliche oder der Auftragsverarbeiter
      unterliegt, können im Einklang mit den in den Artikeln 12 bis 22, Artikel
      34 und den Artikeln 12 bis 22 vorgesehenen Rechten und Pflichten durch
      Gesetzgebungsmaßnahmen eingeschränkt werden. (Artikel 23 der Verordnung)
    </p>
    <p class="text--black">
      Mitteilung der Verletzung des Schutzes personenbezogener Daten an die
      betroffene Person Wenn die Verletzung des Schutzes personenbezogener Daten
      voraussichtlich zu einem hohen Risiko für die Rechte und Freiheiten
      natürlicher Personen führt, unterrichtet der für die Verarbeitung
      Verantwortliche die betroffene Personohne Angabe von Gründen über die
      Verletzung des Schutzes personenbezogener Daten. (Artikel 34 der
      Verordnung)
    </p>
    <p class="text--black">
      Recht auf Beschwerde bei einer Aufsichtsbehörde (Recht auf einen
      Verwaltungsrechtsbehelf) Die betroffene Person hat das Recht, eine
      Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
      ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
      Verstoßes, einzureichen, wenn die betroffene Person der Ansicht ist, dass
      die Verarbeitung der sie betreffenden personenbezogenen Daten gegen die
      Verordnung verstößt. (Artikel 77 der Verordnung)
    </p>
    <p class="text--black">
      Recht auf einen wirksamen gerichtlichen Rechtsbehelf gegen die
      Aufsichtsbehörde Jede natürliche oder juristische Person hat das Recht auf
      einen wirksamen gerichtlichen Rechtsbehelf gegen eine sie betreffende
      rechtsverbindliche Entscheidung einer Aufsichtsbehörde oder wenn die
      Aufsichtsbehörde die Beschwerde nicht bearbeitet oder die betroffene
      Person nicht innerhalb von drei Monaten nach Abschluss oder Ergebnis der
      eingereichten Beschwerde unterrichtet. (Artikel 78 der Verordnung)
    </p>
    <p class="text--black">
      Recht auf wirksame gerichtliche Rechtegegenüber einem Verantwortlichen
      oder einem Auftragsverarbeiter Jede betroffene Person hat das Recht auf
      einen wirksamen gerichtlichen Rechtsbehelf, wenn sie der Ansicht ist, dass
      ihre Rechte aus dieser Verordnung infolge der Verarbeitung
      personenbezogener Daten, die unter Verstoß gegen diese Verordnung
      erfolgten, verletzt wurden. (Artikel 79 der Verordnung)
    </p>

    <h2 class="title--black text-left margin-medium--bottom">
      Was sind Cookies und wie werden sie verwaltet?
    </h2>

    <p class="text--black">
      Cookies sind kleine Dateien (im Folgenden als Cookies bezeichnet), die
      über die Website unter Verwendung der Website an Ihren Computer übertragen
      und von Ihrem Internetbrowser gespeichert und gespeichert werden. Die
      meisten der am häufigsten verwendeten Internetbrowser (Chrome, Firefox
      usw.) akzeptieren und erlauben standardmäßig das Herunterladen und
      Verwenden von Cookies, aber es liegt an Ihnen, sie abzulehnen oder zu
      deaktivieren, indem Sie Ihre Browsereinstellungen ändern, oder Sie können
      gespeicherte Cookies löschen, die sich bereits auf Ihrem Computer
      befinden. Weitere Informationen über die Verwendung von Cookies finden Sie
      im Menü "Hilfe" jedes Browsers.
    </p>
    <p class="text--black w-100">
      Der Datenverantwortliche verwendet oder erlaubt keine Cookies, mit deren
      Hilfeharma dik-Personen ohne Ihre Zustimmung Daten sammeln können.
    </p>
    <p class="text--black">
      Die Annahme von Cookies ist nicht obligatorisch, jedoch übernimmt der
      Datenverantwortliche keine Verantwortung dafür, dass die Website ohne
      Cookies möglicherweise nicht wie erwartet funktioniert.
    </p>
    <p class="text--black">
      Rechtsgrundlage für das Cookie-Management ist dieEinwilligung des
      Besuchers der web-Seite auf der Grundlage des entsprechenden Artikels 6
      Absatz 1 Buchstabe a der Verordnung. Wenn Sie die Verwendung von Cookies
      nicht akzeptieren, sind bestimmte Funktionen der Website, die in Abschnitt
      3.2.3 aufgeführt sind, während der Nutzung der Website nicht verfügbar
      oder einige Funktionen können fehlerhaft sein. Weitere Informationen zum
      Löschen von Cookies für gängigere Browser finden Sie unter den folgenden
      Links:
    </p>
    <ul>
      <li class="text--black privacy-list-item">
        Firefox: Löschen Sie Cookies, die von Websites von Ihrem Computer
        platziert werden
      </li>
      <li class="text--black privacy-list-item">
        Chrome: Löschen Sie c ache & cookies
      </li>
      <li class="text--black privacy-list-item">
        Safari: Cookies und Website-Daten in Safari auf dem Mac verwalten
      </li>
    </ul>

    <p class="text--black">
      Während Ihres Besuchs auf der Website platziert Google Analytics Cookies
      auf Ihrem Computer, diese Anwendung enthält Ihre Besuchsgewohnheiten,
      dieses Unternehmen hat ein berechtigtes Interessedaran, die Daten werden
      auch an Google LLC gesendet. Die Daten werden auf Ihrem Computer
      gespeichert, bis sie gelöscht werden. Wenn Sie nicht möchten, dass das
      analytische Cookie platziert wird, können Sie dies über Ihre
      Browsereinstellungen deaktivieren oder über die von Google bereitgestellte
      Browsererweiterung einschränken: https://tools.google.com/dlpage/gaoptout.
    </p>
    <p class="text--black">
      Soziale Netzwerke wie Facebook, die von ihnen bereitgestellten Funktionen,
      wie Likes und Sharing-Optionen, setzen zusätzliche Cookies, sie enthalten
      Ihre Website-Besuchsgewohnheiten, dies liegt im berechtigten Interesse des
      Unternehmens , die Daten werden auch von Social-Media-Anbietern empfangen.
      Die Daten werden auf Ihrem Computer gespeichert, bis sie gelöscht werden.
      Wenn Sie auf diesen sozialen Plattformen eingeloggt sind, werden die von
      den sozialen Medien bereitgestellten Plug-in-Dienste automatisch
      aktiviert.
    </p>

    <h2
      class="title--black align-self-baseline text-left margin-medium--bottom"
    >
      Cookie-Liste
    </h2>
    <div class="mw-100 privacy-table-container">
      <b-table
        class="margin-medium--bottom"
        striped
        :items="items"
        :fields="fields"
      ></b-table>
    </div>

    <h2 class="title--black text-left margin-medium--bottom">
      ÄNDERUNG DER DATENSCHUTZERKLÄRUNG
    </h2>
    <p class="text--black w-100">
      Der Datenverantwortliche behält sichdas Recht vor, diese Richtlinie
      jederzeit durch seine einseitige Entscheidung zu ändern.
    </p>

    <h2 class="title--black text-left margin-medium--bottom">
      RECHT AUF RECHTSBEHELFE
    </h2>
    <p class="text--black">
      Ist die betroffene Person der Ansicht, dass der Verantwortliche im Rahmen
      ihrer Datenverarbeitung ihr Recht auf Schutz personenbezogener Daten
      verletzt hat, kann sie sich an die nach geltendem Recht zuständigen
      Stellen wenden, d.h. sie kann eine Beschwerde bei der NAIH (Adresse: 1125
      Budapest, Szilágyi Erzsébet fasor 22/c) einreichen oder sich an das
      zuständige Gericht wenden. Der Datenverantwortliche verpflichtet sich,
      dass die betroffene Person während dieser Verfahren mit dem Gericht oder
      der NAIH in allen Bereichen zusammenarbeitet und die Daten im Zusammenhang
      mit der Datenverarbeitung an das zuständige Gericht oder die NAIH
      weitergibt.
    </p>
    <p class="text--black">
      Der Datenverantwortliche verpflichtet sich auch, den Schaden zu ersetzen,
      der durch die unrechtmäßige Verarbeitung der personenbezogenen Daten der
      betroffenen Person oderdurch die Verletzung von
      Datensicherheitsverpflichtungen verursacht wird. Im Falle einer Verletzung
      der Persönlichkeitsrechte der betroffenen Person kann die betroffene
      Person eine Beschwerdegebühr verlangen. Der Verantwortliche ist von der
      Haftung befreit, wenn der Schaden durch eine unvermeidbare Ursache
      außerhalb des Umfangs der Datenverarbeitung verursacht wurde und wenn der
      Schaden oder die Verletzung von Rechten, die durch die Verletzung von
      Persönlichkeitsrechten verursacht wurden, auf vorsätzliches oder grob
      fahrlässiges Verhalten der betroffenen Person zurückzuführen ist.
    </p>

    <h2 class="title--black text-left">IX. VERSCHIEDENE BESTIMMUNGEN</h2>
    <p class="text--black">
      Der Datenverantwortliche verpflichtet sich, sicherzustellen, dass alle
      Datenverarbeitungen im Zusammenhang mit seinen Aktivitäten den in diesem
      Prospekt und in den geltenden Rechtsvorschriften festgelegten Erwartungen
      entsprechen.
    </p>

    <p class="text--black text-left left">
      <strong>VG Elektrotechnik GmbH</strong>
    </p>

    <p class="text--black left">2022.10.16.</p>

    <div class="accordion" role="tablist"></div>
    <div class="position--relative margin-large--bottom">
      <img
        class="help-section-form--right--faq"
        src="../assets/images/svgs/form_5.svg"
      />
      <img
        class="help-section-form--right2--faq2"
        src="../assets/images/svgs/meshlin_main-vector.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "cookie_Name",
        },
        {
          key: "operator",
        },
        {
          key: "Warum ist es für die Website notwendig und welche Funktion bietet sie dem Benutzer? Auf welche Daten können Sie zugreifen?",
          label:
            "Warum ist es für die Website notwendig und welche Funktion bietet sie dem Benutzer? Auf welche Daten können Sie zugreifen?",
        },
        {
          key: "art",
        },
        {
          key: "lebensdauer",
        },
      ],
      items: [
        {
          cookie_Name: "required_cookie",
          operator: "Webseitenbetreiber",
          art: "Notwendig",
          "Warum ist es für die Website notwendig und welche Funktion bietet sie dem Benutzer? Auf welche Daten können Sie zugreifen?":
            "Speichert den Status der vom Benutzer akzeptierten Cookies.",
          lebensdauer: "30 Tage",
        },
        {
          cookie_Name: "statistic_cookie",
          operator: "Webseitenbetreiber",
          art: "Notwendig",
          "Warum ist es für die Website notwendig und welche Funktion bietet sie dem Benutzer? Auf welche Daten können Sie zugreifen?":
            "Speichert den Status der vom Benutzer akzeptierten Cookies.",
          lebensdauer: "30 Tage",
        },
        {
          cookie_Name: "marketing_cookie",
          operator: "Webseitenbetreiber",
          art: "Notwendig",
          "Warum ist es für die Website notwendig und welche Funktion bietet sie dem Benutzer? Auf welche Daten können Sie zugreifen?":
            "Speichert den Status der vom Benutzer akzeptierten Cookies.",
          lebensdauer: "30 Tage",
        },
      ],
    };
  },
};
</script>