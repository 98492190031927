<template>
  <div class="main-section--first--contact">


    <!--    <b-modal v-if="modal" hide-header ok-only visible id="modal-footer-sm" title="BootstrapVue" button-size="sm">-->
    <!--      <p class="my-2">Köszönjük, az üzenetét megkaptuk!<br/>-->
    <!--        Hamarosan felvesszük Önnel a kapcsolatot.</p>-->
    <!--    </b-modal>-->


    <div class="flex-row-resp--md container margin-lr-auto">
      <div class="left-part--contact">
        <div>
          <h1 class="title--big padding-none--vertical center--resp">
            {{ tr("contact") }}
          </h1>
          <div class="container--animation margin---resp center--resp-margin">
            <div id="wrapper">
              <div id="line"><img class="anim-logo" src="../assets/images/logo.jpg" alt="VG elektrotechnik logo"/></div>
            </div>
          </div>
          <div class="center--resp flex-column">
            <h6 class="text--contact text-left margin-medium--bottom-30">
              VG Elektrotechnik GmbH
            </h6>
            <div class="text-left">
              <h6
                  class="text text-left padding-none--resp margin-medium--bottom-30"
              >
                <img class="icon--contact" src="@/assets/images/svgs/gps.svg"/>
                Gewerbestraße 16,
                79112 Freiburg im Breisgau
              </h6>
              <h6
                  class="text text-left padding-none--resp margin-medium--bottom-30"
              >
                <img
                    class="icon--contact"
                    src="@/assets/images/svgs/phone.svg"
                />
                <a @click="setDataLayer('kapcsolat_menupont', 'telefonszam_gombra_kattintas', '')"
                   class="text text-left" href="tel:0049-0-174-2679151">+49 (0) 174 2679151</a>
              </h6>
              <h6 class="text text-left padding-none--resp margin-medium--bottom-30">  <img
                  class="icon--contact"
                  src="@/assets/images/svgs/whatsapp.png"
              /><a class="text text-left" href="https://wa.me/004901742679151">Nachricht auf whatsapp senden</a></h6>
              <h6
                  class="text text-left padding-none--resp margin-medium--bottom-30"
              >
                <img
                    class="icon--contact"
                    src="@/assets/images/svgs/envelope-open-green.svg"
                />
                <a @click="setDataLayer('kapcsolat_menupont', 'email_gombra_kattintas', '')" class="text text-left"
                   href="mailto: www.vgelektrotechnik.com"> <a
                    @click="setDataLayer('Lábléc', 'email_gombra_kattintas', '')" class="text text-left"
                    href="mailto:info@vg-elektrotechnik.com">info@vg-elektrotechnik.com</a>
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="right-part--faq">
        <div
            class="item"
            data-aos="fade-left"
            data-aos-offset="-150"
            data-aos-delay="62"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
        >
          <h2 class="title--white-small--faq resp-text-align-center text-left">
            {{ tr("sendMessage2") }}
          </h2>
          <a href="mailto:info@vg-elektrotechnik.com"> <img
              class="w-50"
              src="@/assets/images/svgs/sending.svg"
          /></a>

        </div>
      </div>
    </div>
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2669.856522754986!2d7.716316716044136!3d47.9971598792125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479111dab1bffb2b%3A0x8090aaea254f5df0!2sVG%20Elektrotechnik%20GmbH!5e0!3m2!1sen!2shu!4v1665841701138!5m2!1sen!2shu"
        width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</template>

<script>
import apiHandlerMI from "@/mixins/apiHandlerMI.js";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Contact'
  },
  mixins: [apiHandlerMI],
  methods: {
    showModal() {
      this.$refs['myModal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    getValidationState({validated, valid = null}) {
      return validated ? valid : null;
    },
  },
  props: {
    imageSrc: String,
  },
  computed: {
    nameState() {
      return !this.form.name || this.form.name.length > 2;
    },
    state() {
      return true;
    },
  },

  created() {
    setTimeout(() => this.sentMessage = false, 4000)
  },
  data() {
    return {
      CONFIG: {
        apiUrl: process.env.VUE_APP_API_BASE_URL,
      },
      infowindow: {lat: 47.68879955510231, lng: 17.66476345767082},
      window_open: true,
      sendingError: false,
      sentMessage: false,
      form: {
        name: null,
        email: null,
        company: null,
        subject: null,
        message: null,
        privacyPolicyAccepted: null,
      },
    };
  },
};
</script>
<style>
.text:hover {
  color: red;
}
</style>
