<template>
  <div>
    <NextMaterialSection learnMoreButtonMode="anchor" title="Wir uber uns" image-src="main2.jpg" />
<!--    <OurHistorySection />-->
<!--    <TitleOfSection1Section />-->
<!--    <TitleOfSection2Section />-->
<!--    <TitleOfSection3Section />-->
    <CanWeHelpSection />
  </div>
</template>


<script>
// @ is an alias to /src
import NextMaterialSection from "@/components/NextMaterialSection.vue";
// import OurHistorySection from "@/components/OurHistorySection.vue";
import CanWeHelpSection from "@/components/CanWeHelpSection.vue";
// import TitleOfSection1Section from "@/components/TitleOfSection1Section.vue";
// import TitleOfSection2Section from "@/components/TitleOfSection2Section.vue";
// import TitleOfSection3Section from "@/components/TitleOfSection3Section.vue";

export default {
  name: "AboutUs",
  components: {
    NextMaterialSection,
    // OurHistorySection,
    CanWeHelpSection,
    // TitleOfSection1Section,
    // TitleOfSection2Section,
    // TitleOfSection3Section,
  },
  mounted(){
    this.setMetaTags({
      title: 'VG-elektrotechnik - wir uber uns',
      desc: 'Seit vielen Jahren bieten wir Ihnen kompetenten Service rund um die Elektroinstallation. Unsere Fachleute kümmern sich schnell und zuverlässig um Planung und Durchführung von Neuinstallationen, Wartungen oder Reparaturen.',
      keywords: 'VG-elektrotechnik wir uber uns',
      ogType: 'website',
      ogTitle: 'VG-elektrotechnik - wir uber uns',
      ogImage: '/images/about-us-2.jpg',
      ogImageWidth: 850,
      ogImageHeight: 635,
      ogDesc:'Seit vielen Jahren bieten wir Ihnen kompetenten Service rund um die Elektroinstallation. Unsere Fachleute kümmern sich schnell und zuverlässig um Planung und Durchführung von Neuinstallationen, Wartungen oder Reparaturen.'
    })
  }
};
</script>