<template>
  <div class="tile--info-full padding--resp--vertical center" ref="customSolutions">
    <div class="tile-info-inner-wrap">
      <div class="info-tile-arrow">
        <img src="@/assets/images/svgs/meshlin-double arrow-right.svg" alt="double arrow"/>
      </div>
      <div class="tile-info__text">
        <div class="item"
             data-aos="fade-down"
             data-aos-offset="-250"
             data-aos-delay="32"
             data-aos-duration="1000"
             data-aos-easing="ease-out-cubic"
             data-aos-mirror="true"
             data-aos-once="true"
             data-aos-anchor-placement="top-center">
        <h2 class="title--500">{{tr('uniqueSolutions')}}</h2>
        </div>
        <p class="text--main-text margin-small--top container--730">
            {{tr('uniqueSolutionsText')}}
          </p>
        <div class="info-tile-arrow arrow--inside">
          <img src="@/assets/images/svgs/meshlin-double arrow-right.svg" alt="double arrow"/>
        </div>
         <img
            class="resp-display--none margin-lr-auto arrow--mobile"
            src="@/assets/images/svgs/meshlin-double arrow-down-dark.svg"
        />
        <router-link
            @click.native="setDataLayer('Lépjen kapcsolatba velünk', 'Kattintás', `${productFamily.name.hu}-alsó`)"
            class="btn btn--green margin-medium--btn margin-lr-auto"
        :to="'/' + $store.state.languageCode + '/' + urlSlug(l('main_menu_item_5'))"
        >
          {{tr('contactButton')}}
        </router-link>
      </div>
      <div class="tile-info-illustration--big">
        <img src="@/assets/images/svgs/tile-info-illustartion.svg" alt="tile"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    productFamily: undefined
  }),
  mounted(){
    this.productFamily = this.$store.state.productFamilies.find(productFamily => this.tr(productFamily.id) === this.$router.currentRoute.params.productFamilyId)
   this.setWideClass()
   window.addEventListener('resize', this.setWideClass, false);
  },
  destroyed(){
    window.removeEventListener('resize', this.setWideClass, false);
  },
  watch:{
    $route(){
      this.setWideClass()    
    }
  },
  methods:{
    setWideClass(){
        const element = this.$refs.customSolutions
        const csWidth = element.offsetWidth
        const csParentwidth =element.parentElement.offsetWidth;
        const widthPercent = Math.round((csWidth / csParentwidth) * 100);

        // console.log('width', csWidth, csParentwidth, widthPercent)

        if (widthPercent < 50) {
          element.classList.add("narrow")
          element.classList.remove("full-width")
          element.classList.remove("half-wide")
        } else if ( widthPercent < 100) {
          element.classList.remove("narrow")
          element.classList.remove("full-width")
          element.classList.add("half-wide")
        } else if (widthPercent == 100){
          element.classList.add("full-width")
          element.classList.remove("narrow")
          element.classList.remove("half-wide")
        }
    }
  },
};
</script>