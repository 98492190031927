<template>
  <div @click="setDataLayer('Termékcsalád', 'Kattintás', productFamily.name.hu)" class="tile-container">
    <img class="tile" :src="`/images/ProductFamilyTiles/${image}.jpg`"/>
    <img class="tileh" :src="`/images/ProductFamilyTiles/${image}h.jpg`"/>
    <span class="text--tile">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    image: String,
    title: String,
    description: String,
  },
  computed: {
    href() {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.productFamily`,
        params: {
          language: this.$store.state.languageCode,
          productFamilyId: this.id,
        },
      }).href;
    },
    productFamilyId() {
      return this.$router.currentRoute.params.productFamilyId;
    },
    productFamily() {
      return this.$store.state.productFamilies.find(productFamily => this.tr(productFamily.id) === this.productFamilyId)
    }
  },
};
</script>